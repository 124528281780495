import script from "./MultipleGeneralization.vue?vue&type=script&lang=ts&setup=true"
export * from "./MultipleGeneralization.vue?vue&type=script&lang=ts&setup=true"

import "./MultipleGeneralization.vue?vue&type=style&index=0&id=0e1cc922&lang=css"
import "./MultipleGeneralization.vue?vue&type=style&index=1&id=0e1cc922&lang=sass"

const __exports__ = script;

export default __exports__
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSeparator,QTable,QInput,QBtn,QTooltip,QSelect,QTr,QTh,QTd,QInnerLoading,QDialog});
